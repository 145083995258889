@use '@onfido/castor';

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 castor.space(4);
  box-sizing: border-box;
  overflow-y: auto;
}

.wrapperBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
