@import '../../Theme/constants';

$parent-width: 432;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.secureLinkView {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  // To fix Safari flexbox rendering issue by forcing the div to be rendered in a new composite layer,
  // see https://stackoverflow.com/questions/65524383/flexbox-bug-on-safari -> https://stackoverflow.com/questions/19169089/weird-css-stretching-issue-in-ios7-safari-and-chrome
  transform: translateZ(0);
}

.selectedLinkView {
  flex: 1;
}

.qrCodeSection {
  margin-top: 13 * $unit;
}

.qrCodeContainer {
  height: 154 * $unit;
  margin-bottom: 13 * $unit;
}

.qrCodeBackground {
  height: 154 * $unit;
  width: 154 * $unit;
  padding: 5 * $unit;
  border-radius: 4 * $unit;
  margin: 0 auto;
  background: var(--osdk-color-background-q-r-code);
}

.smsSection {
  height: 76 * $unit;
  margin-top: 56 * $unit;
}

.label {
  font-weight: 600;
  text-align: initial;
  color: var(--osdk-color-content-body);
}

.numberInputSection {
  display: flex;
  height: 48 * $unit;
  width: 100%;
  margin-top: 4 * $unit;
}

.inputContainer,
.linkText {
  @include width-parent-relative(320, $parent-width);
}

.inputContainer {
  display: flex;
  height: 100%;
  border: 1px solid rgba(var(--ods-color-border-input));
  border-start-start-radius: 4 * $unit;
  border-end-start-radius: 4 * $unit;
  border-inline-end: 0;
  background-color: rgba(var(--ods-color-neutral-white));
  box-shadow: 0 -0.1 * $unit 0 * $unit 0 * $unit rgba(var(--ods-color-border-input));

  form {
    width: 100%;
  }
}

.fieldError {
  border-color: rgba(var(--ods-color-content-negative));
  box-shadow: 0 -0.1 * $unit 0 * $unit 0 * $unit rgba(var(--ods-color-content-negative));
}

.numberError {
  color: rgba(var(--ods-color-content-negative));
  font-size: var(--onfido-font-size-small);
  line-height: 1.43;
  text-align: initial;
  margin-top: 4 * $unit-small;
}

.sending::before {
  margin-left: 12 * $unit;
  width: 16 * $unit;
  height: 16 * $unit;
  content: '';
  opacity: 0.5;

  background-image: url('./assets/spinner.svg');
  background-position: left center;
  background-repeat: no-repeat;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  /* stylelint-disable rule-empty-line-before */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  /* stylelint-enable rule-empty-line-before */
}

.copyLinkSection {
  margin-top: 86 * $unit;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 16 * $unit;
}

.iconCheckmark {
  width: 13 * $unit;
  height: 11 * $unit;
  display: inline-block;
  justify-content: center;
  content: '';
  z-index: 1;
}

.linkText {
  resize: none;
  border: 0;
  height: 24 * $unit;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  line-height: 1.5;
  text-align: initial;
  color: var(--osdk-color-content-body);
  white-space: nowrap;
  font-family: var(--osdk-font-family-body);
}

.copySuccess .copyToClipboard {
  border-bottom-color: transparent;
}

.divider {
  border: 1px solid $color-divider;
  margin-top: 11 * $unit;
}

.btn {
  @include width-parent-relative(112, $parent-width);

  height: 48 * $unit;
  border-radius: 0 4 * $unit 4 * $unit 0;
  padding: 0 8 * $unit;
}

.styledLabel {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.styledLabel::before,
.styledLabel::after {
  content: '';
  border: 1px solid $color-divider;
  flex: 1 0 18 * $unit;
}

.styledLabelText {
  margin: 0 18 * $unit;
}

.viewOptionsGroup {
  margin: 16 * $unit;
  display: flex;
  justify-content: center;
}

.viewOption {
  margin: 0 16 * $unit;
}

.optionIcon {
  width: 22 * $unit;
  height: 16 * $unit;
  display: inline-block;
  content: '';
  vertical-align: text-bottom;
  background-repeat: no-repeat;
}

[dir='rtl'] .optionIcon {
  @extend %icon-rtl;
}
