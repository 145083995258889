@import '../../Theme/constants';

.instructions {
  font-size: var(--onfido-font-size-small);
  text-align: initial;
  margin: 30 * $unit-small 0 24 * $unit-small;
  padding: 24 * $unit-small 24 * $unit-small 12 * $unit-small;
  @media (--small-viewport) and (--short-viewport) {
    font-size: var(--onfido-font-size-x-small);
  }
  @media (--small-viewport) {
    margin: 24 * $unit-small 0 12 * $unit-small;
  }
}

.instructionsTitle {
  font-weight: 600;
  margin: 8 * $unit-small 0;
}

.recovery {
  @extend %header-highlight-pill;
  padding-top: 4 * $unit-small;
  padding-bottom: 4 * $unit-small;
  margin-bottom: 10px;
}

.steps {
  padding: 0;
  margin: 0;
}

.step {
  margin: 0 15 * $unit-small 10 * $unit-small;
}

.button {
  margin-left: auto;
  margin-right: auto;
}
