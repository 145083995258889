@import '../../Theme/constants';

.instructions {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.instructions > span {
  color: $color-white;
  font-family: var(--osdk-font-family-body);
  font-size: var(--onfido-font-size-base);
  margin-bottom: 8 * $unit;

  @media (--short-viewport) {
    font-size: var(--onfido-font-size-x-small);
    margin-bottom: 0;
  }
}

.instructions > .title {
  font-size: 18 * $unit;
  line-height: 1.5;
  font-weight: 600;

  @media (--short-viewport) {
    font-size: var(--onfido-font-size-large);
  }
}

.instructions > .subtitle {
  font-weight: normal;
}

.successIcon {
  // Otherwise overriden by Overlay.document svg (see Overlay/style.scss)
  height: 48 * $unit !important;
}

.instructions .ariaLabel {
  opacity: 0;
}

.instructions .loading {
  align-self: center;
  border-radius: 4 * $unit;
  display: flex;
  height: 4 * $unit;
  margin-top: 16 * $unit;
  overflow: hidden;
  width: 50%;

  > span {
    border-radius: 4 * $unit;
    animation-duration: 1000ms;
  }

  > .active {
    width: 0%;
    background-color: $color-white;
    animation: captureProgress forwards linear;
  }

  > .background {
    width: 100%;
    background-color: var(--onfido-color-background-button-custom);
    opacity: 0.15;
    animation: captureProgress forwards linear reverse;
  }
}

@keyframes captureProgress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
