.baseScreen {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--ods-border-radius-large);
  background: var(--osdk-color-background-surface-modal);
  padding: 0;
}
