@use '@onfido/castor';

.disclaimer {
  display: flex;
  align-items: center;
  text-align: initial;

  svg {
    margin-inline-end: castor.space(1);
    min-width: 24px;
  }

  .text {
    @include castor.font('200-regular');
    color: castor.color('neutral-600');
  }
}
